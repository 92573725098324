import { createSlice } from "@reduxjs/toolkit";

import { loginUser } from "../thunks/auth/login";
import { firstLoginUser } from "../thunks/auth/firstLogin";
import { reset } from "../actions/resetActions";
import { forgotPassword } from "../thunks/auth/forgotPasswod";
import { resetPassword } from "../thunks/auth/resetPassword";

const initialState = {
    isLoading: false,
    isSignedIn: false,
    error: null,
    success: null,
    isFirstLogin: null,
    firstPasswordReset: null,

    forgotPasswordSuccess: null,
    resetPasswordSuccess: null,
    role: null,
    countryData: null,
    userData: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logoutReducer: (state, action) => {
            state.isSignedIn = action.payload;
            state.error = null;
            state.isLoading = false;
            state.success = null;
            state.isFirstLogin = null;
            state.firstPasswordReset = null;
            state.forgotPasswordSuccess = null;
            state.resetPasswordSuccess = null;
        },
        resetState: (state) => {
            state.isUserAdded = null;
            state.isSignedIn = false;
            state.error = null;
            state.isLoading = false;
            state.success = null;
            state.isFirstLogin = null;
            state.firstPasswordReset = null;
            state.forgotPasswordSuccess = null
            state.resetPasswordSuccess = null;
            state.countryData = null    
        },
        setUserData: (state, action) => {
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
        setFirstPasswordReset: (state, action) => {
            state.firstPasswordReset = action.payload
        },
        setIsSignedIn(state, action) {
            state.isSignedIn = action.payload;
        },
        setRole(state, action) {
            state.role = action.payload?.role;
            state.userData = {
                role: action?.payload?.role,
                name: action?.payload?.userData?.name,
                email: action?.payload?.userData?.email,
            }
        },

    },

    extraReducers: (builder) => {
        // reset
        builder.addCase(reset, () => initialState);

        builder.addCase(loginUser.pending, (state) => {
            state.isLoading = true;
            state.success = false;
        });
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSignedIn = true;
            state.success = action?.payload?.message;
            state.isFirstLogin = action?.payload?.data?.isFirstLogin;
            state.role = action?.payload?.data?.role
            state.countryData = action?.payload?.data?.country
            state.userData = {
                role: action?.payload?.data?.role,
                name: action?.payload?.data?.name,
                email: action?.payload?.data?.email,
            }
        });
        builder.addCase(loginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isSignedIn = false;
            state.error = action?.payload?.message;
            state.success = null;
            state.isFirstLogin = null;
            state.userData = null
        });

        // First Login
        builder.addCase(firstLoginUser.pending, (state) => {
            state.isLoading = true;
            state.success = false;
        });
        builder.addCase(firstLoginUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = action?.payload?.message;
            state.firstPasswordReset = true;
        });
        builder.addCase(firstLoginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload?.message;
            state.success = null;
            state.firstPasswordReset = null;
        });

        // Forgot password
        builder.addCase(forgotPassword.pending, (state) => {
            state.isLoading = true;
            state.success = false;
        });
        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = action?.payload?.message;
            state.forgotPasswordSuccess = true;
        });
        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload?.message;
            state.success = null;
            state.forgotPasswordSuccess = false;
        });

        // Reset password
        builder.addCase(resetPassword.pending, (state) => {
            state.isLoading = true;
            state.success = false;
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = action?.payload?.message;
            state.resetPasswordSuccess = true;
        });
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload?.message;
            state.success = null;
            state.resetPasswordSuccess = false;
        });
    },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;