import React, { lazy, Suspense } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Layout from "./layouts/Layout";
import { customDispatch } from "../utils/customDispatch";
import { history } from "../utils/history"
import { useDispatch } from "react-redux";
import RequireAuth from "./layouts/RequireAuth";
import AdminAuth from "./layouts/AdminAuth";
import UserAuth from "./layouts/UserAuth";

const Login = lazy(() => import("./login"));
const FirstLogin = lazy(() => import("./first-login"));
const ForgotPass = lazy(() => import("./forgot-password"));
const AddAccount = lazy(() => import("./admin/add-account"));
const EditAccount = lazy(() => import("./admin/edit-account"));
const AdminListIndex = lazy(() => import("./admin/admin-list-index"));
const DashboardIndex = lazy(() => import("./dashboard/dashboard-index"));
const CuisinesCatList = lazy(() => import("./category-list/list-index"));
const CuisinesCatPopularList = lazy(() => import("./category-list/list-popular-index"));
const ResetPassword = lazy(() => import("./reset-password"));
const Profile = lazy(() => import("./profile"));
const QuartarlyTrendsIndex = lazy(() => import("./quarterly-trends/quartarly-index"));
const TeaCoffeePopular = lazy(() => import("./category-list/TeaCoffeePopular"));

function Router() {
    history.navigate = useNavigate();
    history.location = useLocation();
    customDispatch.dispatch = useDispatch();
    return (
        <Suspense fallback={<div className={"loading"}></div>}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Login />} />
                    <Route path="forgot-password" element={<ForgotPass />} />
                    <Route path="reset-password" element={<ResetPassword />} />

                    {/* User Routes */}
                    <Route element={<UserAuth />}>
                        <Route path="dashboard" element={<DashboardIndex />} />
                        <Route path="first-login" element={<FirstLogin />} />
                        <Route path="category-list" element={<CuisinesCatList />} />
                        <Route path="category-popular-list" element={<CuisinesCatPopularList />} />
                        <Route path="tea-coffee-popular-list" element={<TeaCoffeePopular />} />
                        <Route path="add-account" element={<AddAccount />} />
                        <Route path="edit-account" element={<EditAccount />} />
                        <Route path="admin-list" element={<AdminListIndex />} />
                        <Route path="quarterly-trend" element={<QuartarlyTrendsIndex/>}/>
                    </Route>

                </Route>
            </Routes>
        </Suspense>
    );
}

export default Router;
