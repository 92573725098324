const BASE_URL = process.env.REACT_APP_SERVER_DOMAIN;
const API_ENDPOINT_LOGIN = "/user/login";
const API_ENDPOINT_FIRST_LOGIN = "/user/loginFirstTime";
const API_ENDPOINT_ADD_USER = "/user/create";
const API_ENDPOINT_USER_LIST = "/user/userlist";
const API_ENDPOINT_COUNTRY_LIST = "/country/list";
const API_ENDPOINT_GENERATE_REFRESH_TOKEN = "";
const API_ENDPOINT_FORGOT_PASSWORD = "/user/forgetPassword";
const API_ENDPOINT_RESET_PASSWORD = "/user/resetPassword";
const API_ENDPOINT_EDIT_USER = "/user/update";
const API_ENDPOINT_DELETE_USER = "/user/delete";
const API_ENDPOINT_SUNBURST_DATA = "/dashboard/sunburstChartData";

const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const AUTH_TOKENS_KEY = "authTokens";

const API_ENDPOINT_EDIT_PROFILE_DATA = "/api/user/editProfile";
const API_ENDPOINT_UPLOAD_IMAGE = "/api/image/upload";
const API_ENDPOINT_CATEGORIES_DATA = "/dashboard/chartDataByCategories";
const API_ENDPOINT_POPULAR_COMMON_CUISINES_DATA = "/dashboard/commonPopularCuisines";
const API_ENDPOINT_CUISINES_DISHES = "/dashboard/fetchDishesOfCuisine";
const API_ENDPOINT_TEA_COFFEE = "/dashboard/fetchTeaCoffeeDishes";
const API_ENDPOINT_DROPDOWNS = "/dashboard/fetchDropdowns";
const API_ENDPOINT_QUARTERLY_TREND_CUISINE = "/dashboard/quarterlyTrendCuisines";
const API_ENDPOINT_QUARTERLY_TREND_CUISINE_DISHES = "/dashboard/quarterlyTrendCuisinesDishes";


const CONTENT_TYPES = {
  APPLICATION_JSON: "application/json",
  MULTIPART_FORM_DATA: "multipart/form-data",
};
const TOAST_AUTOCLOSE_DURATION = 2000;
const LOGOUT_MESSAGE = "User Logged Out!";

const EMAIL_REGEX = /^(\w|\.|_|-)+[@](\w|_|-|\.)+[.]\w{2,3}$/;
const NAME_REGEX = /^[a-zA-Z0-9 .]*$/;
const EMAIL_REGEX_USER_ADD = /^(\w|\.|_|-)+@(kerry\.com|tatrasdata\.com|yopmail\.com)$/i; // Dev, QA
const PASSWORD_REGEX =
  /^(?!.*[\s{}[\]()?])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,16}$/;

const LAYOUT = {
  margin: { l: 0, r: 0, b: 0, t: 0 },
  sunburstcolorway: [
    '#636efa',
    '#EF553B',
    '#00cc96',
    '#ab63fa',
    '#FFA07A',
    '#FFD700',
  ],
};

const otherLabel = `Other {'Others'}`
const modifiedOtherLabel = `Others {'Others'}`

const CATEGORIES = [
  {
    name: 'Cuisines',
    value: 'cuisine',
  },
  {
    name: 'Dishes',
    value: 'dish',
  },
  {
    name: 'Beverages',
    value: 'drink',
  }
]

const BARCHART_CATEGORIES = {
  '1': 'Cuisines',
  '2': 'Dishes',
  '3': 'Beverages'
}

const FOODCATEGORYTYPE = {
  all: "All",
  bev: "Beverages",
  dish: "Dishes"
}

const FOODCATEGORYVAL = {
  all: 1,
  bev: 2,
  dish: 3
}

const RESTAURANTCATEGORYTYPE = {
  all: 1,
  fastFood: 2,
  nonFastFood: 3
}

const mainCategory = {
  all: "All",
  beverages: "Beverages",
  dishes: "Dishes"
}
const mainCategoryVal = {
  all: 1,
  beverages: 2,
  dishes: 3
}

const DishesCategory = {
  starters: "Starters",
  mainCourse: "Main course",
  desserts: "Desserts",
}
const DishesCategoryVal = {
  starters: 1,
  mainCourse: 2,
  desserts: 3,
}
const DrinksCategory = {
  tea: "Tea",
  coffee: "Coffee",
  refreshers: "Refreshers",
}
const DrinksCategoryVal = {
  tea: 1,
  coffee: 2,
  refreshers: 3,
}

const DishesCategoryQsr = {
  pizza: "Pizza",
  burger: "Burger",
  chicken: "Chicken",
  other: "Other Dishes"
}
const DishesCategoryValQsr = {
  pizza: 1,
  burger: 2,
  chicken: 3,
  other: 4
}


const MARKER_COLOR = [
  '#98AFC0',
  '#A3E3DE',
  '#7CD6D0',
  '#12B13D',
  '#00A6A6',
  '#6BC151',
  '#59A033',
  '#408B86',
  '#145454',
  '#0BC21D',
  '#98AFC0',
  '#A3E3DE',
  '#7CD6D0',
  '#12B13D',
  '#00A6A6',
  '#6BC151',
  '#59A033',
  '#408B86',
  '#145454',
  '#0BC21D'
]

export {
  BASE_URL,
  API_ENDPOINT_LOGIN,
  API_ENDPOINT_GENERATE_REFRESH_TOKEN,
  API_ENDPOINT_ADD_USER,
  API_ENDPOINT_USER_LIST,
  API_ENDPOINT_COUNTRY_LIST,
  API_ENDPOINT_FIRST_LOGIN,
  API_ENDPOINT_FORGOT_PASSWORD,
  API_ENDPOINT_RESET_PASSWORD,
  API_ENDPOINT_EDIT_USER,
  API_ENDPOINT_DELETE_USER,
  API_ENDPOINT_SUNBURST_DATA,
  API_ENDPOINT_CATEGORIES_DATA,
  API_ENDPOINT_POPULAR_COMMON_CUISINES_DATA,
  API_ENDPOINT_CUISINES_DISHES,
  API_ENDPOINT_TEA_COFFEE,
  API_ENDPOINT_DROPDOWNS,
  API_ENDPOINT_QUARTERLY_TREND_CUISINE,
  API_ENDPOINT_QUARTERLY_TREND_CUISINE_DISHES,
  CONTENT_TYPES,
  API_ENDPOINT_EDIT_PROFILE_DATA,
  API_ENDPOINT_UPLOAD_IMAGE,
  TOAST_AUTOCLOSE_DURATION,
  LOGOUT_MESSAGE,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  AUTH_TOKENS_KEY,
  EMAIL_REGEX,
  EMAIL_REGEX_USER_ADD,
  PASSWORD_REGEX,
  NAME_REGEX,
  LAYOUT,
  CATEGORIES,
  MARKER_COLOR,
  BARCHART_CATEGORIES,
  otherLabel,
  modifiedOtherLabel,
  FOODCATEGORYTYPE,
  FOODCATEGORYVAL,
  RESTAURANTCATEGORYTYPE,
  DishesCategory,
  DishesCategoryVal,
  DrinksCategory,
  DrinksCategoryVal,
  mainCategory,
  mainCategoryVal,
  DishesCategoryQsr,
  DishesCategoryValQsr,

}