import axiosInstance from "../../../utils/axiosInstance"
import { BASE_URL,API_ENDPOINT_QUARTERLY_TREND_CUISINE,API_ENDPOINT_QUARTERLY_TREND_CUISINE_DISHES} from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

/** API to fetch data categorywise [cuisine,dish,drink] */

const quarterlyTrendCuisines = createAsyncThunk("dashboard/quarterlyChartData", async (data, thunkAPI) => {
    try {
      const res = await axiosInstance.post(`${BASE_URL}${API_ENDPOINT_QUARTERLY_TREND_CUISINE}`, data);
      const responseData = await res.data;
      return responseData;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error?.response?.data);
    }
  });

/** API to fetch Dishes of cuisine*/

const quarterlyTrendCuisineDishes = createAsyncThunk("dashboard/quarterlyTrendCuisinesDishes", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}${API_ENDPOINT_QUARTERLY_TREND_CUISINE_DISHES}`, data);
    const responseData = await res.data;
    return responseData;
  } catch (error) {
    return thunkAPI?.rejectWithValue(error?.response?.data);
  }
});
  export { quarterlyTrendCuisines,quarterlyTrendCuisineDishes };